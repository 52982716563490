import { memo } from 'react'
import { Link } from '../Link'
import { mixins, theme } from '../../../styles'
import { Time } from '../Time'
import { Icon } from '../Icon'
import { SVGIcon } from '../SVGIcon'

export interface ILinkArticle {
  color?: string
  date?: string
  index?: number
  isExternal?: boolean
  link?: string
  puceStyle?: ('light' | 'italic')[]
  target?: string
  title: string
  type?: string
  hasMultiLive?: boolean
  onClick?: () => void
}

const LinkArticleComponent = function ({
  color,
  date,
  index,
  isExternal,
  link,
  puceStyle = [],
  title,
  type,
  hasMultiLive,
  target,
  onClick,
}: ILinkArticle): JSX.Element {
  const LinkArticleHeading = link ? 'h3' : 'div'

  return (
    <>
      <div className="LinkArticle flex">
        {index && (
          <div data-testid="LinkArticle__Number" className="LinkArticle__Number">
            {index}
          </div>
        )}
        <div className="flex flex-column">
          <LinkArticleHeading className="LinkArticle__Text">
            {hasMultiLive && <SVGIcon name="direct" size={16} />}
            <Link url={link} onClick={onClick} type={isExternal ? 'extern' : ''} target={target}>
              {title}
            </Link>
          </LinkArticleHeading>
          <div className="LinkArticle__Footer flex items-center">
            {type === 'video' ||
              (type === 'article-video' && (
                <div className="LinkArticle__Footer__Play">
                  <Icon name="play" primaryColor={theme.cssVars.fontColor} width="12px" />
                </div>
              ))}
            {date && (
              <div data-testid="LinkArticle__Date" className="LinkArticle__Date">
                <Time publishedAt={date} />
              </div>
            )}
          </div>
        </div>
      </div>

      <style jsx>{`
        .LinkArticle__Number {
          ${puceStyle.includes('italic') ? 'font-style: italic;' : 'font-style: normal;'}
          ${puceStyle.includes('light') ? 'font-weight: 200;' : 'font-weight: 700;'}
        }
      `}</style>
      <style jsx>{`
        .LinkArticle {
          color: ${color || 'initial'};
        }
      `}</style>
      <style jsx>{`
        .LinkArticle__Text {
          width: 100%;
          margin: auto;
          ${mixins.fonts.article.h6}
        }

        .LinkArticle__Text > :global(svg) {
          margin-right: 5px;
          margin-bottom: -2px;
        }

        .LinkArticle__Number {
          ${mixins.fonts.article.quote}
          width: 25px;
          text-align: center;
          margin-right: 20px;
        }

        .LinkArticle__Date {
          color: ${theme.cssVars.midBlue};
          padding-top: 4px;
          ${mixins.fonts.base.xsmall}
        }

        .LinkArticle__Footer {
          margin-top: 5px;
        }

        .LinkArticle__Footer__Play {
          padding-top: 2px;
          margin-right: 5px;
        }
      `}</style>
    </>
  )
}

export const LinkArticle = memo(LinkArticleComponent)
